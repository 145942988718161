/* eslint-disable react/no-danger */
import Img from 'gatsby-image/withIEPolyfill';
import React, { useState } from 'react';
import buildFunnelUrl from '../../helpers/buildFunnelUrl';
import tt from '../../helpers/translation';
import Buttons from '../Buttons/Buttons';
import Modal from '../Modals/Modal/Modal';
import './cardPassesExpress.scss';

const CardPassesExpress = ({ data: { type, title, logo, priceDay, bottomText, alt }, locale }) => {
  const expressCalendar = `https://portaventura-world-public-files.s3.eu-west-1.amazonaws.com/CALENDARIO_EXPRESS_2022_${locale}_${
    type === 'CAP' ? 'cap' : 'nocap'
  }.pdf`;
  const [isOpen, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <div className="main">
      <Modal isOpen={isOpen} data={bottomText[1]} title={title} closeModal={handleCloseModal} />
      <div className={`card-top ${type}`}>
        <div className="card-logo-title">
          <Img fluid={logo} alt={alt} className="card-img" />
          <h4 className="card-title" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>

      <div className="container-text">
        <div className="prices-container">
          <div className="prices">
            <div className="price">{priceDay}€</div>
            <span className="day">
              {type === 'CAP' ? tt('2 PERSONAS / DÍA', locale) : tt('PERSONA/DIA', locale)}
            </span>
          </div>
        </div>
        <div className="bottom-text" dangerouslySetInnerHTML={{ __html: bottomText[0] }} />
        <div className="bottom-btn">
          <Buttons
            size="alone"
            link="#"
            color="gris-banner"
            text={tt('PRÓXIMAMENTE', locale)}
            // link={buildFunnelUrl(locale, 'tickets')}
            // color="orange"
            // text={tt('¡COMPRA AHORA!', locale)}
          />
          <div className="bottom-link-container">
            <button type="button" className="bottom-link" onClick={handleModal}>
              <span>
                <b>{tt('Condiciones', locale)}</b>
              </span>
            </button>
            {title !== 'Express Street Mission' && (
              <a href={expressCalendar} target="_blank" rel="noreferrer" className="bottom-link">
                <span>
                  <b>{tt('Calendario', locale)}</b>
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPassesExpress;
